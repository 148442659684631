import departamentos from './diccionarios/departamentos';
import municipios from './diccionarios/municipios';
import departamentosSV from './diccionarios/departamentosSV';
import municipiosSV from './diccionarios/municipiosSV';
import departamentosHN from './diccionarios/departamentosHN';
import municipiosHN from './diccionarios/municipiosHN';
import monedas from './diccionarios/monedas';
import paises from './diccionarios/paises';
import ajustesGenerales from './diccionarios/ajustesGenerales';

const LOCAL_DEV = false;
const PROD_API = true;
let AMBIENTE = 'SV'; // GT - Guatemala / SV - El Salvador
const VERSION = '1.24.14-' + AMBIENTE + (PROD_API ? '-PROD' : '-QA');

export class APIUrls {
    public readUsername = 'api-read';
    public readPassword = 'CcuKr234LVxA';
    public writeUsername = 'api-write';
    public writePassword = '_wdm3Q1Q9hw2J';
    public deleteUsername = 'api-delete';
    public deletePassword = 'm1wHzZA612O5c';
    public version = VERSION;
    public defaultTimezone = 'America/Guatemala';
    public defaultTimezoneHours = -6;
    public localDev = LOCAL_DEV;
    public prodAPI = PROD_API;
    public AMBIENTE = AMBIENTE;
    public host = LOCAL_DEV ?
        'localhost:1730' :
        'continental-motores-apitesting.herokuapp.com'
    ;
    public urlPortalCitas = (
        AMBIENTE == 'GT' ? 'https://cmconnect.continentalmotores.dev/' :
        (
            AMBIENTE == 'SV' ?
            'https://cmconnectsv.continentalmotores.dev/' :
            'https://cmconnecthn.continentalmotores.dev/'
        )
    );

    public baseURLGT = LOCAL_DEV ?
        'http://localhost:1731/api/' :
        PROD_API ?
            'https://cmapp.continentalmotores.dev/api/' :
            'https://qacmapp.continentalmotores.dev/api/'
        ;
    public baseURLSV = LOCAL_DEV ?
        'http://localhost:1731/api/' :
        PROD_API ?
            'https://cmappsv.continentalmotores.dev/api/' :
            'https://qacmappsv.continentalmotores.dev/api/'
        ;
    public baseURLHN = LOCAL_DEV ?
        'http://localhost:1731/api/' :
        PROD_API ?
            'https://cmapphn.continentalmotores.dev/api/' :
            'https://qacmapphn.continentalmotores.dev/api/'
        ;
    public baseURL = (
        AMBIENTE == 'GT' ? this.baseURLGT :
        (
            AMBIENTE == 'SV' ?
            this.baseURLSV :
            this.baseURLHN
        )
    );

    public baseBestuneURLGT = LOCAL_DEV ?
        'D:/Documentos/Work/Estudio Faser/Continental motores/continental-motores-ive-node-api/uploads/bestune/' :
        PROD_API ?
            'https://cmapp.continentalmotores.dev/bestune/' :
            'https://qacmapp.continentalmotores.dev/bestune/'
        ;
    
    public pdfURL = 'https://html-to-pdf.azurewebsites.net/api/HttpTriggerCSharp1?code=HecijiGy7QhVfAiW6T13UhGpzrXE3p7q9rY0XSLso1Q1IMgYBYr1DA==';
    public logoURL = 'https://apollo-virginia.akamaized.net/v1/files/4eh1u7nnsei41-GT/image%3Bp%3Dfull';

    public uploadURL = PROD_API ?
        'https://cmapp.continentalmotores.dev/api/' :
        'https://qacmapp.continentalmotores.dev/api/'
    ;

    public nombreSitio = 'Continental Motores';
    public nombreSitioTitle = ' - ' + this.nombreSitio;

    public anioDesde = 1999;
    public anioHasta = new Date().getFullYear();

    public usuariosURL = 'usuarios';
    public rolesURL = 'roles';
    public empresasURL = 'empresas';
    public clientesURL = 'clientes';
    public productosURL = 'productos';
    public ventasURL = 'ventas';
    public formulariosFEICURL = 'formulariosFEIC';
    public listasNegrasURL = 'listasNegras';
    public marcaVehiculosURL = 'marcaVehiculo';
    public marcaAhorrentURL = 'marcaAhorrent';
    public lineaVehiculosURL = 'lineaVehiculo';
    public lineaAhorrentURL = 'lineaAhorrent';
    public ajusteAvaluoURL = 'ajusteAvaluo';
    public otroDescuentoURL = 'descuentos';
    public avaluoURL = 'avaluos';
    public membretesEmpresasURL = 'membretesEmpresas';
    public hojasMembretadasURL = 'hojasMembretadas';
    public historialHojaMembretada = 'historialHojasMembretadas';
    public historialCambioEstadoURL = 'cambioEstadoAvaluo';
    public unidadesURL = 'unidades';
    public clientesAhorrentURL = 'clientes-ahorrent';
    public configuracionAhorrentURL = 'configuracion-ahorrent';
    public documentosClientesURL = 'documentos-clientes';
    public seguimientosClientesURL = 'seguimientos-clientes';
    public cambiosEstadosUnidadesURL = 'cambios-estados-unidad';
    public cambiosEstadosContratosURL = 'cambios-estados-contratos-ahorrent';
    public lavadosEspecialesURL = 'lavados-especiales';
    public serviciosUnidadesURL = 'servicios-unidades';
    public gastosServiciosURL = 'gastos-servicios';
    public campaniasPreventivasURL = 'campanias-preventivas';
    public vehiculosCampaniasURL = 'vehiculos-campanias';
    public reservacionesCampaniasURL = 'reservaciones-campanias';
    public contratosAhorrentURL = 'contratos-ahorrent';
    public concesionariosURL = 'concesionarios';
    public citasURL = 'citas';
    public serviciosURL = 'servicios';
    public repuestosURL = 'repuestos';
    public versionesURL = 'versiones';
    public serviciosPendientesUnidadesURL = 'servicios-pendientes-unidades';
    public versioneslineasSOBURL = 'versiones-linea-sob';
    public vehiculosSOBURL = 'vehiculos-sob';
    public configuracionLandingURL = 'configuracion-landing';
    public solicitudesPruebasManejosURL = 'solicitudes-pruebas-manejos';
    public configuracionDigitalizacionURL = 'configuracion-digitalizacion';
    public cambiosEstadosSolicitudPruebaManejosURL = 'cambios-estados-solicitudes-pruebas-manejos'
    public documentosSolicitudesPruebasManejosURL = 'documentos-solicitudes-pruebas-manejos'
    public vehiculosDemoURL= 'vehiculos-demo';
    public clientesBoletasURL= 'clientes-boletas';
    public clientesPlanesMantenimientoURL= 'clientes-planes-mantenimiento';
    public vehiculosPlanMantenimientoURL= 'vehiculos-planes-mantenimiento';
    public planesMantenimientoURL= 'planes-mantenimiento';
    public itemsPlanesMantenimientoURL= 'items-planes-mantenimiento';
    public contratacionesURL = 'contrataciones';
    public ordenesTrabajoURL = 'ordenes-trabajo';
    public daniosAhorrentURL = 'danios-ahorrent';
    public documentosContratacionesURL = 'documentos-contrataciones';
    public reportesPlanesMantenimientoURL = 'reportes-planes-mantenimiento';
    public configuracionPlanMantenimientoURL = 'configuraciones-planes-mantenimiento';
    public configuracionPDIURL = 'configuracion-pdi';
    public solicitudesPDIURL = 'solicitudes-pdi';
    public cambioEstadoSolicitudPDIURL = 'cambios-estados-solicitudes-pdi';
    public datosVentasURL = 'datos-ventas';
    public clientesPresupuestoURL = 'clientes-presupuestos';
    public configuracionPresupuestoURL = 'configuracion-presupuestos';
    public presupuestosURL = 'presupuestos';
    public cambioEstadoPresupuestosURL = 'cambios-estados-presupuestos'
    public puntosPresupuestoURL = 'puntos-presupuestos'
    //itemsPuntoPresupuesto
    public itemsPuntoPresupuestoURL = 'items-puntos-presupuestos'
    public repuestosItemsPuntoPresupuestoURL = 'repuestos-items-puntos-presupuestos'

    // Requisiciones
    public requisicionesURL = 'requisiciones';
    public configuracionRequisicionURL = 'configuracion-requisicion';
    public cambioEstadoRequisicionURL = 'cambio-estado-requisicion';
    public detallesRequisicionURL = 'detalles-requisicion';

    //SolicitudTraspasos
    public solicitudTraspasosURL = 'solicitud-traspasos'
    public cambioEstadoSolicitudTraspasoURL = 'cambio-estado-solicitud-traspasos'
    public configuracionDigitalizacionTraspasosURL = 'configuracion-digitalizacion-traspasos'
    public historialFirmaPromesaURL = 'historial-firmas-promesas'
    public historialFirmaTraspasoURL = 'historial-firmas-traspasos'

    public historialItemsPresupuestosURL = 'historial-items-presupuestos'
    // Leads
    public configuracionLeadURL = 'configuraciones-leads';
    public prospectoLeadURL = 'prospectos-leads';
    public cambioEstadoProspectoLeadURL = 'cambios-estados-prospectos-leads';
    public seguimientoProspectoLeadURL = 'seguimientos-prospectos-leads';
    //imagenes unidades
    public imagenesUnidadesURL = 'imagenes-unidades'
    public pendientesUnidadesURL = 'pendientes-unidades'
    // Documentos unidades
    public documentosUnidadesURL = 'documentos-unidades'
    public facturasSVURL = 'facturas-sv'
    public configuracionFacturasSVURL = 'configuracion-facturas-sv'
    public valoresFacturasEspecialesURL = 'valores-facturas-especiales'
    public cajerosFacturasSVURL = 'cajeros-facturas-sv'
    // Multas Ahorrent
    public multasAhorrentURL = 'multas-ahorrent';
    public cambiosFechasContratosAhorrentURL = 'cambios-fechas-contrato-ahorrent';
    // Cambios de ubicaciones de unidades
    public cambiosUbicacionUnidadesURL = 'cambios-ubicaciones-unidades';

    // observaciones de clientes ahorrent
    public observacionesClientesAhorrentURL = 'observaciones-clientes-ahorrent';

    //ReclamoRentaClienteAhorrent
    public reclamosRentaClienteAhorrentURL = 'reclamos-renta-clientes-ahorrent';

    public reclamosGeneralesClienteAhorrentURL = 'reclamos-generales-clientes-ahorrent'

    // OrdenTallerKanboard
    public ordenTallerKanboardURL = 'ordenes-talleres-kanboard'

    public configuracionOrdenTallerKanboardURL = 'configuracion-ordenes-talleres-kanboard'
    public cambioEstadoOrdenTallerKanboardURL = 'cambios-estados-ordenes-talleres-kanboard'

    // Encuestas
    public encuestaClienteAhorrentURL = 'encuestas-clientes-ahorrent';

    // Bestune
    public configuracionBestuneURL = 'configuracion-bestune';
    public repuestosBestuneURL = 'repuesto-bestune';
    
    quillModules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ],
        }
    };

    defaultImagenServicios = 'assets/img/demo/servicios.jpeg';

    noRedirectRoutes = [
        '/reservacion-citas/reservar',
        '/reservacion-citas/datos',
        '/vw/buscar-vehiculo',
        '/vw/transparencia-precios',
        '/encuesta-individual',
        '/encuesta-corporativa',
        '/kiosko',
    ]

    public meses = [
        { id: 1, nombre: 'Enero', show: true },
        { id: 2, nombre: 'Febrero', show: true },
        { id: 3, nombre: 'Marzo', show: true },
        { id: 4, nombre: 'Abril', show: true },
        { id: 5, nombre: 'Mayo', show: true },
        { id: 6, nombre: 'Junio', show: true },
        { id: 7, nombre: 'Julio', show: true },
        { id: 8, nombre: 'Agosto', show: true },
        { id: 9, nombre: 'Septiembre', show: true },
        { id: 10, nombre: 'Octubre', show: true },
        { id: 11, nombre: 'Noviembre', show: true },
        { id: 12, nombre: 'Diciembre', show: true }
    ];


    opcionesSeguimiento = [
        { id: 'telefono', nombre: 'Teléfono' },
        { id: 'correo', nombre: 'Correo electrónico' },
        { id: 'whatsapp', nombre: 'Whatsapp' },
        { id: 'presencial', nombre: 'Visita presencial' },
        { id: 'agencia', nombre: 'Atención en agencia' },
        { id: 'otro', nombre: 'Otro' },
    ];

    estadoUnidad = [
        { id: 'rentado', nombre: 'Rentado', color: '#2eb032' },
        { id: 'disponible', nombre: 'Disponible', color: '#7ee6a6' },
        { id: 'servicio', nombre: 'En servicio', color: '#f49224' },
        { id: 'lavado', nombre: 'En lavado', color: '#f49224'  },
        { id: 'reparacion', nombre: 'En reparación', color: '#ff5959' },
        { id: 'inactivo', nombre: 'Inactivo', color:'#c2bdc1' },
        { id: 'en-venta', nombre: 'En venta', color:'#eec5e9' },
        { id: 'perdida-total', nombre: 'Pérdida total', color:'#a2ecf5' },
        { id: 'retenido', nombre: 'Retenido', color:'#fafc7e' },
    ];

    nivelesGasolinaAhorrent = [
        null,
        'Lleno',
        '7/8',
        '6/8',
        '5/8',
        'Medio',
        '3/8',
        '2/8',
        '1/8',
        'Reserva',
    ];

    equivalenciasNivelesGasolinaAhorrent = [
        { id: null,         valor: 0 },
        { id: 'Reserva',    valor: 0 },
        { id: '1/8',        valor: 1 },
        { id: '2/8',        valor: 2 },
        { id: '3/8',        valor: 3 },
        { id: 'Medio',      valor: 4 },
        { id: '5/8',        valor: 5 },
        { id: '6/8',        valor: 6 },
        { id: '7/8',        valor: 7 },
        { id: 'Lleno',      valor: 8 },
    ];

    lineasCitas = [
        'Amarok V6',
        'Saveiro',
        'Tiguan',
        'Polo',
        'Jetta',
        'JMT',
        'Polo HB',
        'Golf GTI',
        'Fox Xtreme',
        'Passat',
        'Virtus',
        'Touareg',
        'Constellation 17-250',
        'Transporter T6',
        'Amarok',
    ]

    tipoServicioCitas = [
        { id: 'servicio-mantenimiento', nombre: 'Servicio / Mantenimiento' },
        { id: 'reparacion', nombre: 'Reparación' },
        { id: 'otro', nombre: 'Otro' },
    ];

    rangosServicioCitas = [
        { id: 'rango1', nombre: 'Rango 1' },
        { id: 'rango2', nombre: 'Rango 2' },
        { id: 'rango3', nombre: 'Rango 3' },
    ];

    tipoServicio = [
        { id: 'servicio', nombre: 'Servicio' },
        { id: 'reparacion', nombre: 'Reparación' },
    ];

    tipoProveedor = [
        { id: 'interno', nombre: 'Interno' },
        { id: 'proveedor', nombre: 'Proveedor' },
    ];

    tiposFormulariosFEIC = [
        { id: 'individual', nombre: 'Invidivual' },
        { id: 'juridico', nombre: 'Jurídico' },
    ];

    condicionesMigratorias = [
        { id: 1, nombre: 'Residente temporal' },
        { id: 2, nombre: 'Residente permanente' },
        { id: 3, nombre: 'Turista o visitante' },
        { id: 4, nombre: 'Permiso de trabajo' },
        { id: 5, nombre: 'Persona en tránsito' },
        { id: 6, nombre: 'Permiso consular o similar' },
        { id: 7, nombre: 'Nacionalizado/Naturalizado en Guatemala' },
        { id: 8, nombre: 'Otra' },
    ];

    sexos = [
        { id: 1, nombre: 'Femenino' },
        { id: 2, nombre: 'Masculino' },
    ];

    establecimientosInformacion = [
        { id: 'I', nombre: 'Perfil inicial' },
        { id: 'A', nombre: 'Actualización de perfil' },
    ];

    tiposModificacionesInformacion = [
        { id: 1, nombre: 'Nombre' },
        { id: 2, nombre: 'Patrimonio (capital)' },
        { id: 3, nombre: 'Objeto' },
    ];

    tiposPersonasEstructuraAccionaria = [
        { id: 'I', nombre: 'Invidivual' },
        { id: 'J', nombre: 'Jurídica' },
    ];

    origenesRiqueza = [
        'Trabajos anteriores',
        'Negocios propio',
        'Servicios profesionales',
        'Préstamos bancarios',
        'Bienes muebles e inmuebles por herencia',
        'Bienes muebles e inmuebles',
        'Otros',
    ];

    calidadEnLaQueActua = [
        'Administrador único',
        'Mandato',
        'Tutor',
        'Fiador',
        'Cónyuge',
        'Donante',
    ];

    tiposEmpresarRelacionadas = [
        'INVERSIONES LA CAPITAL',
        'VEHÍCULOS AUTOMOTORES DE GUATEMALA, S.A.',
        'SEGUROS CONTINENTAL, S.A.',
    ];

    tiposOrganizacion = [
        { id: 'anonima', nombre: 'Sociedad Anónima' },
        { id: 'organizacion-gubernamental', nombre: 'Organización Gubernamental' },
        { id: 'partido-politico', nombre: 'Partido Político' },
        { id: 'organizacion-sin-lucro', nombre: 'Organizaciones sin fines de Lucro' },
        { id: 'fundacion', nombre: 'Fundación' },
        { id: 'embajada', nombre: 'Embajada' },
        { id: 'limitas', nombre: 'Limitas' },
        { id: 'comandita', nombre: 'Comandita' },
        { id: 'otras', nombre: 'Otras' },
    ];

    parentescos = [
        'Padre',
        'Madre',
        'Hijo(a)',
        'Hermano(a)',
        'Cónyuge',
        'Otros',
    ];

    condicionesNacionalidad = [
        'Nacional',
        'Extranjero',
    ];

    motivosAsociacion = [
        'Profesionales',
        'Políticos',
        'Comerciales',
        'Negocios',
        'Otros',
    ];

    documentosIdentificacion = [
        { id: 1, nombre: 'DPI' },
        { id: 2, nombre: 'Pasaporte' },
    ];

    empresaPropiedad = [
        { id: 1, nombre: 'Empresa propiedad del cliente' },
        { id: 2, nombre: 'Nombre propio' },
    ];

    fuentesIngresosJuridico = [
        { id: 1, nombre: 'Venta / Prestación de productos / Servicios' },
        { id: 2, nombre: 'Donaciones' },
        { id: 3, nombre: 'Asignación presupuestaria' },
        { id: 4, nombre: 'Aportaciones' },
        { id: 5, nombre: 'Financiamiento' },
        { id: 6, nombre: 'Utilidades' },
        { id: 7, nombre: 'Subvenciones' },
        { id: 8, nombre: 'Rentas' },
        { id: 9, nombre: 'Otro ' },
    ];

    profesiones = [
        { nombre: 'Carpintero' },
        { nombre: 'Albañil' },
        { nombre: 'Secretaria' },
        { nombre: 'Contador' },
        { nombre: 'Auditor' },
        { nombre: 'Jardinero' },
        { nombre: 'Administrador' },
        { nombre: 'Mensajero' },
        { nombre: 'Mecánico' },
        { nombre: 'Cajero' },
        { nombre: 'Bailarín' },
        { nombre: 'Ayudante de Albañil' },
        { nombre: 'Ejecutivo de Ventas' },
        { nombre: 'Gerente' },
        { nombre: 'Sub-Gerente' },
        { nombre: 'Jefe de Logística' },
        { nombre: 'Diseñador' },
        { nombre: 'Programador' },
    ];

    estadosCiviles = [
        { id: 1, nombre: 'Soltero' },
        { id: 2, nombre: 'Casado' },
    ];

    sectores = [
        'Privado',
        'Público',
        'Otros',
    ];

    actividades = [
        { nombre: 'AGRICULTURA' },
        { nombre: 'ALIMENTOS' },
        { nombre: 'ASESORIA' },
        { nombre: 'COMBUSTIBLES' },
        { nombre: 'CONSTRUCCION' },
        { nombre: 'GANADERIA' },
        { nombre: 'PESCA' },
        { nombre: 'PUBLICIDAD' },
        { nombre: 'SEGURIDAD' },
        { nombre: 'SERVICIOS' },
        { nombre: 'TELEFONIA' },
        { nombre: 'TRANSPORTE' },
        { nombre: 'OTROS' },
    ];

    tiposIngresos = [
        'ACTIVIDADES',
        'ACTIVIDADES PROFESIONALES',
        'MANUTENCION',
        'RENTA',
        'JUBILACION',
        'REMESAS',
        'OTRO',
    ];

    rangosIngresosJuridicoEgresos = [
        { nombre: '0.00 - 25,000.00', valor: 5000 },
        { nombre: '25,000.01 - 100,000.00', valor: 75000 },
        { nombre: '100,000.01 - 400,000.00', valor: 150000 },
        { nombre: '400,000.01 - 700,000.00', valor: 550000 },
        { nombre: '700,000.01 - 1,000,000.00', valor: 850000 },
        { nombre: 'Mayor a 1,000,000.00', valor: 1000001 },
    ];

    fondosPropios = [
        { id: 1, nombre: 'Fondos propios' },
        { id: 2, nombre: 'Fondos de un tercero' },
        { id: 3, nombre: 'Fondos propios y de un tercero' },
    ];

    fondosTercero = [
        { id: 'PI', nombre: 'Persona individual' },
        { id: 'PJ', nombre: 'Persona jurídica' },
        { id: 'PU', nombre: 'Persona individual y jurídica' },
    ];

    rangosIngresosIndividualEgresos = [
        { nombre: '0.00 - 3,000.00', valor: 1500 },
        { nombre: '3,000.01 - 10,000.00', valor: 5000 },
        { nombre: '10,000.01 - 50,000.00', valor: 25000 },
        { nombre: '50,000.01 - 100,000.00', valor: 75000 },
        { nombre: '100,000.01 - 200,000.00', valor: 150000 },
        { nombre: 'Mayor a 200,000.00', valor: 200001 },
    ];

    lugares = [
        { id: 'agencia-z1', nombre: 'Agencia Z. 1' },
        { id: 'agencia-z2', nombre: 'Agencia Z. 2' },
        { id: 'agencia-z3', nombre: 'Agencia Z. 3' },
        { id: 'agencia-z4', nombre: 'Agencia Z. 4' },
        { id: 'agencia-z5', nombre: 'Agencia Z. 5' },
        { id: 'agencia-z6', nombre: 'Agencia Z. 6' },
        { id: 'agencia-z7', nombre: 'Agencia Z. 7' },
        { id: 'agencia-z8', nombre: 'Agencia Z. 8' },
        { id: 'agencia-z9', nombre: 'Agencia Z. 9' },
        { id: 'agencia-z10', nombre: 'Agencia Z. 10' },
        { id: 'agencia-z11', nombre: 'Agencia Z. 11' },
        { id: 'agencia-z12', nombre: 'Agencia Z. 12' },
        { id: 'agencia-z13', nombre: 'Agencia Z. 13' },
    ];

    tiposVentas = [
        'Contado',
        'Crédito Bancario',
        'Leasing',
        'Crédito interno',
    ];

    tiposTransferencias = [
        'Local',
        'Internacional'
    ];

    tiposReferencias = [
        'Personal',
        'Laboral',
        'Comercial',
        'Bancaria'
    ];

    clasesDeVehiculos = [
        'Sedán',
        'Hatchback',
        'Pickup',
        'SUV',
        'Camioneta',
        'Camión'
    ];

    tiposSolicitudesPDI = [
        { id: 'solicitud', nombre: 'Facturado' },
        { id: 'exhibicion', nombre: 'Exhibición/Sustitución' },
        { id: 'licitacion', nombre: 'Licitación' },
    ]

    tiposFechasSolicitudesPDI = [
        //fechaActual
        //{ id: 'fechaActual', nombre: 'Fecha Actual' },
        //fechaEntregadoCliente
        { id: 'fechaEntregadoCliente', nombre: 'Fecha Entregado Cliente' },
    ]

    tiposVehiculo = [
        { id: 'sedan', nombre: 'Sedán' },
        { id: 'pickup', nombre: 'Pickup' },
        { id: 'suv', nombre: 'SUV' },
        { id: 'panel', nombre: 'Pánel' },
        { id: 'camion', nombre: 'Camión' },
        { id: 'microbus', nombre: 'Microbús' },
    ];

    prioridadesPendientesUnidad = [
        { id: 'alta', nombre: 'Alta' },
        { id: 'media', nombre: 'Media' },
        { id: 'baja', nombre: 'Baja' },
    ];

    estadosPendientesUnidad = [
        { id: 'pendiente', nombre: 'Pendiente' },
        { id: 'en-proceso', nombre: 'En proceso' },
        { id: 'finalizado', nombre: 'Finalizado' },
    ];

    tipoDescuento = [
        { nombre: 'Interior', id: 'interior' },
        { nombre: 'Exterior', id: 'exterior' },
    ];

    tipoAplicacionDescuento = [
        { nombre: 'Por Unidad', id: 'unidad' },
        { nombre: 'Único', id: 'unico' },
    ];

    rangosPorcentajes = [
        { nombre: '0% - 30%', id: 0 },
        { nombre: '31% - 60%', id: 1 },
        { nombre: '61% - 90%', id: 2 },
        { nombre: '91% - 100%', id: 3 },
    ]

    rangosFechas = [
        { id: 'mes-actual', nombre: 'Mes actual' },
        { id: 'mes-pasado', nombre: 'Mes pasado' },
        { id: 'ano-actual', nombre: 'Año actual' },
        { id: 'ano-pasado', nombre: 'Año pasado' },
        { id: 'esta-semana', nombre: 'Esta semana' },
        { id: 'ultima-semana', nombre: 'Semana pasada' },
        { id: 'personalizado', nombre: 'Personalizado' },
    ];

    tipoAsiento = [
        { id: 'tela', nombre: 'Tela' },
        { id: 'cuero', nombre: 'Cuero' },
    ];

    tipoAvaluo = [
        { id: 'compra-venta', nombre: 'Compra venta' },
        { id: 'consignacion', nombre: 'Consignación' },
        { id: 'compra-directa', nombre: 'Compra directa por nuevo' },
        { id: 'compra-directa-usados', nombre: 'Compra directa usados' }
    ];

    estadosProspecto = [
        { id: 'facturado', nombre: 'Facturado', color: '#00FF00' },
        { id: 'reservado', nombre: 'Reservado', color: '#2EFEF7' },
        { id: 'cita', nombre: 'Cita', color: '#FF8000' },
        { id: 'seguimiento', nombre: 'Seguimiento', color: '#FFFF00' },
        { id: 'desistio', nombre: 'Desistió', color: '#FF0000' },
    ]

    // Para la tabla de  /seguimiento-prospectos/
    estadosParaSeguimientoProspecto = [
      { id: 'reservado', nombre: 'Reservado' },
      { id: 'cita', nombre: 'Cita' },
      { id: 'seguimiento', nombre: 'Seguimiento' },
  ]

    estadoAvaluo = [
        { id: 'pendiente-avaluo', nombre: 'Avalúo solicitado' },
        { id: 'pendiente-confirmacion', nombre: 'Pendiente de confirmación de precio' },
        { id: 'avaluo-entregado', nombre: 'Avalúo entregado' },
        { id: 'aprobado-por-cliente', nombre: 'Aprobado por cliente' },
        { id: 'rechazado-por-cliente', nombre: 'Rechazado por cliente' },
        { id: 'cancelado', nombre: 'Cancelado' },
        { id: 'recibido', nombre: 'Recibido' },
        { id: 'descartado', nombre: 'Descartado' },
        { id: 'aprobado-no-entregado-cliente', nombre: 'Aprobado no entregado por cliente' },
    ];

    estadosContratosAhorrent = [
        { id: 'reserva',    nombre: 'Reserva', color: '#25c628'  },
        { id: 'abierto',    nombre: 'Abierto', color: '#f7e108'  },
        { id: 'cerrado',    nombre: 'Cerrado', color: '#a2a2a2' },
        { id: 'anulado',    nombre: 'Anulado', color: '#ff0000' },
    ];

    estadosReservasAhorrent = [
        { id: 'reserva',    nombre: 'Reserva', color: '#25c628'  },
        { id: 'anulado',    nombre: 'Anulado', color: '#ff0000' },
    ];

    transmision = [
        { id: 'automatico', nombre: 'Automático' },
        { id: 'mecanico', nombre: 'Mecánico' },
        { id: 'mecanico-4', nombre: 'Mecánico 4 velocidades' },
        { id: 'mecanico-5', nombre: 'Mecánico 5 velocidades' },
        { id: 'mecanico-6', nombre: 'Mecánico 6 velocidades' },
    ];

    estadoLlantas = [
        { id: 'bueno', nombre: 'Bueno' },
        { id: 'regular', nombre: 'Regular' },
        { id: 'malo', nombre: 'Malo' }
    ];

    estadoHojaMembrete = [
        { id: 'edicion', nombre: 'Edición' },
        { id: 'esperando-aprobacion', nombre: 'Esperando aprobación' },
        { id: 'aprobado', nombre: 'Aprobado para impresión' }
    ];

    tipoCliente = [
        { id: 'individual', nombre: 'Individual' },
        { id: 'empresarial', nombre: 'Empresarial' }
    ];

    diasAtencionCitas = [
        { id: 'lunes', nombre: 'Lunes' },
        { id: 'martes', nombre: 'Martes' },
        { id: 'miercoles', nombre: 'Miércoles' },
        { id: 'jueves', nombre: 'Jueves' },
        { id: 'viernes', nombre: 'Viernes' },
        { id: 'sabado', nombre: 'Sábado' },
        { id: 'domingo', nombre: 'Domingo' },
    ];

    tiposHistorial = [
        { id: 'edicion-contenido', nombre: 'Edición de contenido' },
        { id: 'creacion-hoja', nombre: 'Creación de hoja membretada' },
        { id: 'duplicacion-hoja', nombre: 'Duplicación de hoja membretada' },
        { id: 'solicitud-impresion', nombre: 'Solicitud de impresión' },
        { id: 'aprobacion-impresion', nombre: 'Aprobación de impresion' },
        { id: 'rechazo-impresion', nombre: 'Rechazo de impresión' },
        { id: 'eliminacion-hoja', nombre: 'Eliminación de hoja membretada' },
        { id: 'descarga-hoja', nombre: 'Descarga de hoja membretada' },
        { id: 're-edicion-hoja', nombre: 'Regreso a edición' },
    ];

    inspeccionGrafica = [
        { id: 'paragolpesTrasero', nombre: 'Paragolpes trasero' },
        { id: 'luzDelanteraDerecha', nombre: 'Luz delantera derecha' },
        { id: 'luzDelanteraIzquierda', nombre: 'Luz delantera izquierda' },
        { id: 'luzTraseraDerecha', nombre: 'Luz trasera derecha' },
        { id: 'luzTraseraIzquierda', nombre: 'Luz trasera izquierda' },
        { id: 'panelTrasero', nombre: 'Panel trasero' },
        { id: 'baul', nombre: 'Baúl' },
        { id: 'parabrisasTrasero', nombre: 'Parabrisas trasero' },
        { id: 'techo', nombre: 'Techo' },
        { id: 'quemacocos', nombre: 'Quema cocos' },
        { id: 'parabrisasDelantero', nombre: 'Parabrisas delantero' },
        { id: 'capo', nombre: 'Capó' },
        { id: 'capoIzquierdo', nombre: 'Capó izquierdo' },
        { id: 'capoDerecho', nombre: 'Capó derecho' },
        { id: 'farosDelanteros', nombre: 'Faros delanteros' },
        { id: 'faroDelanteroDerecho', nombre: 'Faro delantero derecho' },
        { id: 'faroDelanteroIzquierdo', nombre: 'Faro delantero izquierdo' },
        { id: 'paragolpesDelantero', nombre: 'Paragolpes delantero' },
        { id: 'baulIzquierda', nombre: 'Baúl izquierdo' },
        { id: 'llantaTraseraIzquierda', nombre: 'llanta trasera izquierda' },
        { id: 'ventanaTraseraIzquierda', nombre: 'Ventana trasera izquierda' },
        { id: 'puertaTraseraIzquierda', nombre: 'Puerta trasera izquierda' },
        { id: 'ventanaFrontalIzquierda', nombre: 'Ventana frontal izquierda' },
        { id: 'puertaFrontalIzquierda', nombre: 'Puerta frontal izquierda' },
        { id: 'llantaFrontalIzquierda', nombre: 'Llanta frontal izquierda' },
        { id: 'llantaDelanteraIzquierda', nombre: 'Llanta delantera izquierda' },
        { id: 'capoIzquierda', nombre: 'Capó izquierdo' },
        { id: 'puertaDerecha', nombre: 'Puerta derecha' },
        { id: 'puertaIzquierda', nombre: 'Puerta izquierda' },
        { id: 'baulDerecha', nombre: 'Baúl derecha' },
        { id: 'llantaTraseraDerecha', nombre: 'Llanta trasera derecha' },
        { id: 'ventaTraseraDerecha', nombre: 'Ventana trasera derecha' },
        { id: 'puertaTraseraDerecha', nombre: 'Puerta trasera derecha' },
        { id: 'ventanaFrontalDerecha', nombre: 'Ventana frontal derecha' },
        { id: 'puertaFrontalDerecha', nombre: 'Puerta frontal derecha' },
        { id: 'ventanaTraseraDerecha', nombre: 'Ventana trasera derecha' },
        { id: 'llantaFrontalDerecha', nombre: 'Llanta frontal derecha' },
        { id: 'llantaDelanteraDerecha', nombre: 'Llanta delantera derecha' },
        { id: 'capoDerecha', nombre: 'Capó derecho' },
        { id: 'estriboDerecho', nombre: 'Estribo derecho' },
        { id: 'estriboIzquierdo', nombre: 'Estribo izquierdo' },
        { id: 'retrovisorDerecho', nombre: 'Retrovisor derecho' },
        { id: 'retrovisorIzquierdo', nombre: 'Retrovisor izquierdo' },
        { id: 'lateralIzquierdo', nombre: 'Lateral izquierdo' },
        { id: 'lateralDerecho', nombre: 'Lateral derecho' },
        { id: 'palanganaIzquierda', nombre: 'Palangana izquierda' },
        { id: 'palanganaDerecha', nombre: 'Palangana derecha' },
        { id: 'palangana', nombre: 'Palangana' },
        { id: 'puertaPalangana', nombre: 'Puerta de Palangana' },
        { id: 'puertaDelanteraIzquierda', nombre: 'Puerta delantera izquierda' },
        { id: 'puertaDelanteraDerecha', nombre: 'Puerta delantera derecha' },
        { id: 'ventanillaDelanteraIzquierda', nombre: 'Ventanilla delantera izquierda' },
        { id: 'ventanillaDelanteraDerecha', nombre: 'Ventanilla delantera derecha' },
        { id: 'puertasCorredizasIzquierda', nombre: 'Puertas corredizas izquierda' },
        { id: 'puertasCorredizasDerecha', nombre: 'Puertas corredizas derecha' },
        { id: 'ventanillasIzquierda', nombre: 'Ventanillas izquierda' },
        { id: 'ventanillasDerecha', nombre: 'Ventanillas derecha' },
        { id: 'puertaTrasera', nombre: 'Puerta trasera' },
    ];

    estadosSolicitudPruebaManejo  = [
        { id: 'pendiente', nombre: 'Pendiente', titulo:'pendientes', color: '#f4bf17' },
        { id: 'aprobado', nombre: 'Aprobado', titulo:'aprobadas', color: '#34be21' },
        { id: 'en-uso', nombre: 'En uso', titulo:'en uso', color: '#f0970e' },
        { id: 'finalizado', nombre: 'Finalizado', titulo:'finalizadas', color: 'green' },
        { id: 'rechazado', nombre: 'Rechazado', titulo:'rechazadas', color: '#c6c5c5' },
    ];

    tiposSolicitudPruebaManejo  = [
        { id: 'prueba-manejo', nombre: 'Prueba de manejo' },
        { id: 'prestamo', nombre: 'Préstamo' },
    ];

    tiposFacturasSV  = [
        { id: 'nuevos-usados', nombre: 'Nuevos y usados' },
        { id: 'repuestos', nombre: 'Repuestos' },
        { id: 'taller-servicios', nombre: 'Taller/servicios' },
        { id: 'exportacion-vehiculos', nombre: 'Exportación de vehículos' },
        { id: 'exportacion-repuestos', nombre: 'Exportación de repuestos' },
        { id: 'ahorrent', nombre: 'Ahorrent' },
    ];

    estadosFacturasSV  = [
        { id: 'no-iniciada', nombre: 'No iniciada', color: '#ffcfcf' },
        { id: 'error-emision', nombre: 'Error en certificación', color: '#ffcfcf' },
        { id: 'en-proceso', nombre: 'En proceso', color: '#fff7cf' },
        { id: 'certificada', nombre: 'Certificada', color: '#daffd1' },
        { id: 'certificada-contingencia', nombre: 'Certificada en contingencia', color: '#daffd1' },
        { id: 'factura-especial', nombre: 'Factura especial', color: '#d9d9d9' },
        { id: 'factura-especial-serie', nombre: 'Factura especial serie', color: '#d9d9d9' },
        { id: 'anulada', nombre: 'Anulada', color: '#ffcfcf' },
        { id: 'nota-credito', nombre: 'Nota de crédito', color: '#fff7cf' },
        { id: 'anulada-integra', nombre: 'Anulada Integra', color: '#f7cfff' },
    ];

    origenesCita = [
        {id: 1, nombre: 'Volkswagen - Digital'},
        {id: 2, nombre: 'Volkswagen - Partner'},
    ];

    suborigenesCita = [
        { id: 1, nombre: 'Service Online Book - Web' },
        { id: 2, nombre: 'Service Online Book - DMS' },
        { id: 16, nombre: 'Service Online Book - My VW Mobile' },
        { id: 32, nombre: 'Service Online Book - My VW Automotive' },
    ];

    propiedadUnidad = [
        { id: 'propio', nombre: 'Propio' },
        { id: 'subarrendado', nombre: 'Subarrendado' },
    ];

    preferenciasContactos = [
        { id: 0, nombre: "Otro" },
        { id: 1, nombre: "Email" },
        { id: 2, nombre: "Teléfono" },
        { id: 3, nombre: "Whatsapp" },
    ]

    companiasPDI = [
        { id: 'CM', nombre: "CM", paises: ['GT', 'SV'] },
        { id: 'VA', nombre: "VA", paises: ['GT'] },
        { id: 'IR', nombre: "IR", paises: ['SV'] },
    ]

    statusOrdenTallerKanboard = [
        { id: 'kanboard', nombre: "Kanboard" },
        { id: 'en-proceso', nombre: "En proceso" },
        { id: 'finalizado', nombre: "Finalizado" },
    ];

    // Para encuestas
    tipoPregunta = [
        { id: 'formato-1-radio', nombre: 'Formato 1 (radio)' },
        { id: 'formato-2-checkbox', nombre: 'Formato 2 (checkbox)' },
        { id: 'formato-3-select', nombre: 'Formato 3 (select)' },
        { id: 'formato-4-texto', nombre: 'Formato 4 (texto)' },
    ]

    tipoLlenado = [
        { id: 'individual-correo', nombre: 'Individual (correo)' },
        { id: 'corporativa', nombre: 'Corporativa' },
        { id: 'individual-kiosko', nombre: 'Individual (kiosko)' },
    ]

    estadoEncuestaClienteAhorrent = [
        { id: 'abierto', nombre: 'Abierto' },
        { id: 'cerrado', nombre: 'Cerrado' },
    ]

    horasAbogados = [
        { id: '1', nombre: '08:00-09:00' },
        { id: '2', nombre: '09:00-10:00' },
        { id: '3', nombre: '10:00-11:00' },
        { id: '4', nombre: '11:00-12:00' },
        { id: '5', nombre: '12:00-13:00' },
        { id: '6', nombre: '13:00-14:00' },
        { id: '7', nombre: '14:00-15:00' },
        { id: '8', nombre: '15:00-16:00' },
        { id: '9', nombre: '16:00-17:00' },
    ]

    ajustesGeneralesPorDefecto = ajustesGenerales;
    monedas = monedas;
    paises = paises;
    departamentos = (
        AMBIENTE == 'GT' ? departamentos :
        (
            AMBIENTE == 'SV' ?
            departamentosSV :
            departamentosHN
        )
    );
    municipios = (
        AMBIENTE == 'GT' ? municipios :
        (
            AMBIENTE == 'SV' ?
            municipiosSV :
            municipiosHN
        )
    );

    public registrosUrls = [
        'http://localhost:4200/registro',
        'http://localhost:4200/registro/',
        'https://continental-motores-ive-testing.herokuapp.com/registro',
        'https://continental-motores-ive-testing.herokuapp.com/registro/',
    ]

    public permisos = {
        SUPER_ADMIN: 'Súper Admin',
        ADMINISTRADOR: 'Administrador',
        AUDITOR: 'Auditor',
        VISUALIZAR_VENTAS: 'Visualizar ventas',
        EDITAR_VENTAS: 'Editar ventas',
        VISUALIZAR_FORMULARIOS: 'Visualizar formularios',
        EDITAR_FORMULARIOS: 'Editar formularios',
        VISUALIZAR_CLIENTES: 'Visualizar clientes',
        EDITAR_CLIENTES: 'Editar clientes',
        VISUALIZAR_PRODUCTOS: 'Visualizar productos',
        VISUALIZAR_USUARIOS: 'Visualizar usuarios',
        CERRAR_FORMULARIOS: 'Cerrar formularios',
        EXPORTAR_FORMULARIOS: 'Exportar formularios',
        EDITAR_PRODUCTOS: 'Editar productos',
        MATRIZ_RIESGO: 'Matriz de riesgo',
        EDITAR_USUARIOS: 'Editar usuarios',
        ELIMINAR_USUARIOS: 'Eliminar usuarios',
        AJUSTES_GENERALES: 'Ajustes generales de matriz de riesgo',
        VISUALIZAR_EMPRESAS: 'Visualizar empresas',
        EDITAR_EMPRESAS: 'Editar empresas',
        EDITAR_FORMULARIOS_CERRADOS: 'Editar Formularios Cerrados',
        CREACION_SOLICITUD_AVALUO: 'Creación de solicitud de avalúo',
        CONFIGURACION_AVALUOS: 'Configuración de avalúo',
        CONFIRMACION_AVALUO: 'Confirmación de avalúo',
        REALIZACION_AVALUO: 'Realización de avalúo',
        REPORTE_GERENCIAL_AVALUOS: 'Reporte gerencial de Avalúos',
        DASHBOARD_AVALUO: 'Dashboard de avalúos',
        REPORTE_AVALUO: 'Reporte de avalúos',
        REPORTE_COACHES: 'Reporte coaches de avalúos',
        CANCELAR_AVALUOS: 'Cancelar avalúos',
        CREACION_MEMBRETE_EMPRESA: 'Editar membretes de empresa',
        VISUALIZAR_MEBRETES_EMPRESA: 'Visualización de membretes de empresa',
        CREACION_HOJA_MEMBRETADA: 'Creación de hojas membretadas',
        APROBACION_HOJAS_MEMBRETADAS: 'Aprobación de hojas membretadas',
        DESBLOQUEAR_HOJAS_MEMBRETADAS: 'Desbloquear hojas membretadas aprobadas',
        REPORTE_HOJAS_MEMBRETADAS: 'Reporte de hojas membretadas',
        VER_AVALUOS_CONFIRMADOS: 'Ver avalúos confirmados',
        ELIMINAR_VENTAS_FORMULARIOS: 'Eliminar ventas y formularios',

        // Requisiciones
        CONFIGURACION_REQUISICIONES: 'Configuración de requisiciones',
        CREACION_REQUISICIONES: 'Creación de nuevas requisiciones',
        LECTURA_REQUISICIONES: 'Lectura de requisiciones',
        EDICION_REQUISICIONES: 'Edición de requisiciones',
        CANCELAR_REQUISICIONES: 'Cancelar requisiciones',
        FORZAR_CAMBIO_ESTADO_REQUISICIONES: 'Forzar cambio de estado de requisición',
        LECTURA_REQUISICIONES_CREADAS: 'Lectura de requisiciones creadas',
        LECTURA_REQUISICIONES_PENDIENTES_DE_COTIZACION: 'Lectura de requisiciones pendientes de cotización',
        LECTURA_REQUISICIONES_PENDIENTES_DE_VALIDACION: 'Lectura de requisiciones pendientes de validación',
        LECTURA_REQUISICIONES_APROBACION_DE_COTIZACION: 'Lectura de requisiciones aprobación de cotización',
        LECTURA_REQUISICIONES_COLOCACION_DE_PEDIDO: 'Lectura de requisiciones colocación de pedido',
        LECTURA_REQUISICIONES_ESTATUS_DEL_REQUERIMIENTO: 'Lectura de requisiciones estatus del requerimiento',
        LECTURA_REQUISICIONES_PEDIDO_COMPLETO_PENDIENTE_DE_DESPACHO: 'Lectura de requisiciones pedido completo pendiente de despacho',
        LECTURA_REQUISICIONES_PEDIDO_FINALIZADO: 'Lectura de requisiciones pedido finalizado',
        LECTURA_REQUISICIONES_ANULACION_DE_PEDIDO: 'Lectura de requisiciones anulación de pedido',
        AUTORIZACION_REQUISICIONES_TALLER: 'Autorización de requisición taller',
        LECTURA_TODAS_REQUISICIONES: 'Lectura de todas las requisiciones',
        AUTORIZACION_INGRESO_BACKORDER_DESPACHADO_REQUISICIONES: 'Autorización de Ingreso, Backorder y Despachado en Requisiciones',

        VISUALIZAR_UNIDADES: 'Visualizar unidades',
        EDITAR_UNIDADES: 'Editar unidades',
        VISUALIZAR_CLIENTES_AHORRENT: 'Visualizar clientes ahorrent',
        EDITAR_CLIENTES_AHORRENT: 'Editar clientes ahorrent',
        CAMBIOS_ESTADO_UNIDADES_AHORRENT: 'Cambios de estado de unidades Ahorrent',
        CAMBIOS_ESTADO_CONTRATOS_AHORRENT: 'Cambios de estado de contratos Ahorrent',
        INGRESO_SERVICIOS_UNIDADES_AHORRENT:'Ingreso a servicio de unidades ahorrent',
        CONFIGURACION_CAMPANIAS:'Configuración de campañas',
        VISUALIZAR_CONTRATOS_AHORRENT: 'Visualizar contratos ahorrent',
        EDITAR_CONTRATOS_AHORRENT: 'Editar contratos ahorrent',
        ABRIR_CONTRATOS_CERRADOS_AHORRENT: 'Abrir contratos cerrados ahorrent',
        VISUALIZAR_RESERVAS_AHORRENT: 'Visualizar reservas Ahorrent',
        EDITAR_RESERVAS_AHORRENT: 'Editar reservas Ahorrent',
        EDITAR_CONCESIONARIOS: 'Editar concesionarios',
        VISUALIZAR_CITAS: 'Visualización de citas',
        APROBACION_CITAS: 'Aprobación de citas',
        AGENTE_CITAS: 'Agente para reservar citas',
        REPORTE_CITAS: 'Reporte de citas',
        EDITAR_SERVICIOS: 'Editar servicios',
        VISUALIZAR_SERVICIOS: 'Visualizar servicios',
        EDITAR_REPUESTOS: 'Editar repuestos',
        VISUALIZAR_REPUESTOS: 'Visualizar repuestos',
        EDITAR_VERSIONES: 'Editar versiones',
        VISUALIZAR_VERSIONES: 'Visualizar versiones',
        SERVICIOS_PENDIENTES_UNIDADES_AHORRENT: 'Servicios pendientes unidades ahorrent',
        EDITAR_INSPECCION_SALIDA: 'Editar inspección de salida',
        EDITAR_INSPECCION_ENTRADA: 'Editar inspección de entrada',
        CONFIGURACION_LANDING: 'Configuración de landing page',
        CONFIGURACION_AHORRENT: 'Configuración de ahorrent',
        LISTADO_ENCUESTAS_INDIVIDUALES_AHORRENT: 'Listado de encuestas individuales Ahorrent',
        LISTADO_ENCUESTAS_CORPORATIVAS_AHORRENT: 'Listado de encuestas corporativas Ahorrent',
        GRAFICAS_ENCUESTAS_AHORRENT: 'Gráficas de encuestas Ahorrent',
        DASHBOARD_AGENTES_AHORRENT: 'Dashboard de agentes Ahorrent',
        DASHBOARD_GERENCIAL_AHORRENT: 'Dashboard gerencial Ahorrent',
        DASHBOARD_PILOTOS_AHORRENT: 'Dashboard pilotos Ahorrent',
        DASHBOARD_ANALISIS_RENTAS_AHORRENT: 'Dashboard análisis rentas Ahorrent',
        REGISTRAR_LAVADO_ESPECIAL_AHORRENT: 'Registrar lavado especial Ahorrent',
        APROBAR_LAVADO_ESPECIAL_AHORRENT: 'Aprobar lavado especial Ahorrent',
        CREACION_SOLICITUD_PRUEBA_MANEJO: 'Creación de solicitud de prueba de manejo',
        EDICION_SOLICITUD_PRUEBA_MANEJO: 'Edición de solicitud de prueba de manejo',
        LECTURA_SOLICITUD_PRUEBA_MANEJO: 'Lectura de solicitud de prueba de manejo',
        APROBAR_SOLICITUD_PRUEBA_MANEJO: 'Aprobar solicitudes de prueba de manejo',
        CALENDARIO_SOLICITUD_PRUEBA_MANEJO: 'Calendario de solicitudes de prueba de manejo',
        REPORTES_SOLICITUD_PRUEBA_MANEJO: 'Reportes de solicitudes de prueba de manejo',
        EDICION_VEHICULOS_SOLICITUDES: 'Edición de vehículos de solicitud de prueba de manejo',
        LECTURA_VEHICULOS_SOLICITUDES: 'Lectura de vehículos de solicitud de prueba de manejo',
        VISUALIZAR_CLIENTES_BOLETAS: 'Visualizar clientes boleta',
        EDITAR_CLIENTES_BOLETAS: 'Editar clientes boleta',
        APROBAR_SOLICITUD_PRESTAMO: 'Aprobar solicitudes de prestamo',
        VISUALIZAR_CLIENTES_PLANES_MANTENIMIENTOS: 'Visualizar clientes planes mantenimientos',
        EDITAR_CLIENTES_PLANES_MANTENIMIENTOS: 'Editar clientes planes mantenimientos',
        VISUALIZAR_VEHICULOS_PLANES_MANTENIMIENTOS: 'Visualizar vehículos planes mantenimientos',
        EDITAR_VEHICULOS_PLANES_MANTENIMIENTOS: 'Editar vehículos planes mantenimientos',
        VISUALIZAR_PLANES_MANTENIMIENTOS: 'Visualizar planes mantenimientos',
        EDITAR_PLANES_MANTENIMIENTOS: 'Editar planes mantenimientos',
        VISUALIZAR_CONTRATACIONES: 'Visualizar contrataciones',
        EDITAR_CONTRATACIONES: 'Editar contrataciones',
        VISUALIZAR_ORDENES_TRABAJO: 'Visualizar ordenes de trabajo',
        EDITAR_ORDENES_TRABAJO: 'Editar ordenes de trabajo',
        DASHBOARD_COMERCIAL_AVALUO: 'Dashboard comercial de avalúos',
        CREACION_SOLICITUD_VENTA: 'Creación de solicitud de venta',
        LECTURA_SOLICITUD_VENTA: 'Lectura de solicitud de venta',
        AUTORIZACION_SOLICITUD_VENTA: 'Autorización de solicitudes ventas',
        AGREGAR_ACCESORIOS_FUERA_ACUERDO: 'Agregar accesorios fuera de acuerdo',
        ENVIO_SOLICITUD_VENTA_KANBOARD: 'Envio de solicitud de venta a kanboard',
        CAMBIO_ENVIO_PLACAS: 'Cambio de envio de placas',
        DATOS_VENTAS: 'Datos de ventas',
        DESCARGA_TAREAS_KANBOARD: 'Descargar tareas kanboard',
        CAMBIO_STATUS_SOLICITUDES_PDI: 'Cambio de estatus solicitudes PDI',
        CANCELAR_SOLICITUDES_PDI: 'Cancelar solicitudes PDI',
        EXHIBICIONES_PDI: 'Exhibiciones PDI',
        NUEVA_EXHIBICION_PDI: 'Nueva exhibiciones PDI',
        DATOS_EXHIBICIONES: 'Datos exhibición',
        CAMBIO_STATUS_EXHIBICIONES_PDI: 'Cambio de estatus exhibiciones PDI',
        CANCELAR_EXHIBICIONES_PDI: 'Cancelar exhibiciones PDI',
        LICITACIONES_PDI: 'Licitaciones PDI',
        NUEVA_LICITACION_PDI: 'Nueva licitación PDI',
        DATOS_LICITACIONES: 'Datos licitaciones',
        CAMBIO_STATUS_LICITACIONES_PDI: 'Cambio de estatus licitaciones PDI',
        CANCELAR_LICITACIONES_PDI: 'Cancelar licitaciones PDI',
        EDICION_CAMPOS_SOLICITUD_PDI: 'Edición de campos especiales solicitudes PDI',
        REPORTE_PDI: 'Reporte de PDI',
        LECTURA_INSPECCION_SALIDA_PDI: 'Lectura de inspección de salida PDI',
        EDICION_INSPECCION_SALIDA_PDI: 'Edición de inspección de salida PDI',
        LECTURA_INSPECCION_ENTRADA_PDI: 'Lectura de inspección de entrada PDI',
        EDICION_INSPECCION_ENTRADA_PDI: 'Edición de inspección de entrada PDI',
        
        VISUALIZAR_CLIENTES_PRESUPUESTO: 'Visualizar clientes presupuesto',
        EDITAR_CLIENTES_PRESUPUESTO: 'Editar clientes presupuesto',
        CONFIGURACION_PRESUPUESTO: 'Configuración presupuesto',
        VISUALIZAR_PRESUPUESTO: 'Visualizar presupuesto',
        EDITAR_PRESUPUESTO: 'Editar presupuesto',
        ELIMINAR_PRESUPUESTO: 'Eliminar presupuesto',
        CAMBIOS_ESTADO_PRESUPUESTOS: 'Cambios de estado de presupuestos',
        //Punto presupuesto
        VISUALIZAR_PUNTOS_PRESUPUESTO: 'Visualizar puntos presupuesto',
        EDITAR_PUNTOS_PRESUPUESTO: 'Editar puntos presupuesto',
        ELIMINAR_PUNTOS_PRESUPUESTO: 'Eliminar puntos presupuesto',

        // item mano de obra
        VISUALIZAR_ITEM_MANO_OBRA: 'Visualizar item mano de obra',
        EDITAR_ITEM_MANO_OBRA: 'Editar item mano de obra',
        ELIMINAR_ITEM_MANO_OBRA: 'Eliminar item mano de obra',

        // item repuesto
        VISUALIZAR_ITEM_REPUESTO: 'Visualizar item repuesto',
        EDITAR_ITEM_REPUESTO: 'Editar item repuesto',
        ELIMINAR_ITEM_REPUESTO: 'Eliminar item repuesto',       

        RECHAZO_MANO_OBRA: 'Rechazo mano de obra',
        AUTORIZAR_REPUESTO_IMPORTADO: 'Autorizar repuesto importado',
        AUTORIZAR_REPUESTO_BODEGA_LOCAL: 'Autorizar repuesto bodega local',
        MODIFICAR_AVALUOS: 'Modificación de avalúos',

        
        //  historial items
        VISUALIZAR_HISTORIAL_ITEMS: 'Visualizar historial items',

        DESCARGAR_EXCEL_PRESUPUESTOS: 'Descargar excel presupuestos',

        //Visualizar datos generales presupuestos
        VISUALIZAR_DATOS_GENERALES_PRESUPUESTOS: 'Visualizar datos generales presupuestos',
        //Editar
        EDITAR_DATOS_GENERALES_PRESUPUESTOS: 'Editar datos generales presupuestos',

        //ENVIAR CORREO CLIENTE PRESUPUESTO
        ENVIAR_CORREO_CLIENTE_PRESUPUESTO: 'Enviar correo cliente presupuesto',
        //Validar presupuestos
        VALIDAR_PRESUPUESTOS: 'Validar presupuestos',
        PRESUPUESTO_RECUPERADO: 'Presupuesto recuperado',    

        //Nuevos permisos de presupuestos

        ANULAR_PRESUPUESTO: 'Anular presupuesto',
        CAMBIAR_ESTADO_ABIERTO_PRESUPUESTO: 'Cambiar a estado abierto presupuesto',
        FINALIZAR_PRESUPUESTO: 'Finalizar presupuesto',
        ENVIAR_CORREO_REPUESTOS_PRESUPUESTO: 'Enviar correo repuestos presupuesto',
        ENVIAR_CORREO_MANO_OBRA_PRESUPUESTO: 'Enviar correo mano de obra presupuesto',
        DESCARGAR_PRESUPUESTO_PRESUPUESTO: 'Descargar presupuesto presupuesto',
        PREVIEW_PRESUPUESTO_PRESUPUESTO: 'Preview de presupuesto presupuesto',
        CONFIGURAR_PRIORIDAD_PRESUPUESTO: 'Configurar prioridad presupuesto',
        CONFIGURAR_PORCENTAJE_DESCUENTO_MANO_OBRA_PRESUPUESTO: 'Configurar porcentaje de descuento mano de obra presupuesto',
        
        // ProspectosLeads
        EDICION_CONFIGURACION_LEAD: 'Configuración de Lead',
        LECTURA_PROSPECTOS_LEADS: 'Lectura de prospectos leads',
        EDICION_PROSPECTOS_LEADS: 'Edición de prospectos leads',
        CAMBIO_STATUS_PROSPECTO_LEAD: 'Cambio de estado prospecto lead',
        SEGUIMIENTOS_PROSPECTOS_LEADS: 'Seguimiento de prospectos y leads',


        ACCESO_TODOS_PROSPECTOS_LEADS: 'Acceso a ver todos los prospectos y leds',
        SEGUIMIENTOS_PROSPECTOS_LEADS_DE_OTROS_USUARIOS: 'Seguimiento a Leds de otros usuarios',
        CAMBIO_STATUS_FINALIZADO_PROSPECTO_LEAD: 'Cambio estatus Finalizados',
        REPORTE_PROSPECTOS_LEADS: 'Reporte de prospectos y leads',

        //IMAGEN UNIDAD
        VISUALIZAR_IMAGEN_UNIDAD: 'Visualizar imagen unidad',
        EDITAR_IMAGEN_UNIDAD: 'Editar imagen unidad',

        //Depreciación UNIDAD
        VISUALIZAR_DEPRECIACION_UNIDAD: 'Visualizar depreciación de unidad',
        EDITAR_DEPRECIACION_UNIDAD: 'Editar depreciación de unidad',

        // Facturas SV
        LISTADO_FACTURAS_NUEVOS_USADOS: 'Listado de facturas El Salvador de nuevos y usados',
        LISTADO_FACTURAS_REPUESTOS: 'Listado de facturas El Salvador de repuestos',
        LISTADO_FACTURAS_TALLER_SERVICIOS: 'Listado de facturas El Salvador de taller y servicios',
        LISTADO_FACTURAS_EXPORTACION_VEHICULOS: 'Listado de facturas El Salvador de exportación de vehículos',
        LISTADO_FACTURAS_EXPORTACION_REPUESTOS: 'Listado de facturas El Salvador de exportación de repuestos',
        LISTADO_FACTURAS_AHORRENT: 'Listado de facturas El Salvador de Ahorrent',
        EDITAR_FACTURAS_SV: 'Edición de datos de facturas El Salvador',
        EMITIR_FACTURAS_SV: 'Emisión de documentos El Salvador',
        ANULAR_FACTURAS_SV: 'Anulación de documentos El Salvador',
        CONFIGURACION_FACTURAS_SV: 'Configuración de módulo Facturas El Salvador',

        // Configuración Ahorrent
        VISUALIZAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL: 'Visualizar Conf. Ahorrent Encuesta individual',
        EDITAR_CONF_AHORRENT_ENCUESTA_INDIVIDUAL: 'Editar Conf. Ahorrent Encuesta individual',
        VISUALIZAR_CONF_AHORRENT_ENCUESTA_CORPORATIVA: 'Visualizar Conf. Ahorrent Encuesta corporativa',
        EDITAR_CONF_AHORRENT_ENCUESTA_CORPORATIVA: 'Editar Conf. Ahorrent Encuesta corporativa',
        VISUALIZAR_CONF_AHORRENT_TEXTOS_ENCUESTA: 'Visualizar Conf. Ahorrent Textos encuesta finalizada',
        EDITAR_CONF_AHORRENT_TEXTOS_ENCUESTA: 'Editar Conf. Ahorrent Textos encuesta finalizada',
        VISUALIZAR_CONF_AHORRENT_SERVICIOS_KILOMETRAJE: 'Visualizar Conf. Ahorrent Servicios por kilometraje',
        EDITAR_CONF_AHORRENT_SERVICIOS_KILOMETRAJE: 'Editar Conf. Ahorrent Servicios por kilometraje',
        VISUALIZAR_CONF_AHORRENT_CAMBIO_DOLAR: 'Visualizar Conf. Ahorrent Cambio dólar',
        EDITAR_CONF_AHORRENT_CAMBIO_DOLAR: 'Editar Conf. Ahorrent Cambio dólar',
        VISUALIZAR_CONF_AHORRENT_PRECIOS_COMBUSTIBLE: 'Visualizar Conf. Ahorrent Precios de combustible',
        EDITAR_CONF_AHORRENT_PRECIOS_COMBUSTIBLE: 'Editar Conf. Ahorrent Precios de combustible',
        VISUALIZAR_CONF_AHORRENT_PROTECCION_CHOQUES: 'Visualizar Conf. Ahorrent Protección contra choques',
        EDITAR_CONF_AHORRENT_PROTECCION_CHOQUES: 'Editar Conf. Ahorrent Protección contra choques',
        VISUALIZAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_EFICIENCIA: 'Visualizar Conf. Ahorrent Porcentajes de eficiencia',
        EDITAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_EFICIENCIA: 'Editar Conf. Ahorrent Porcentajes de eficiencia',
        VISUALIZAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_DESCUENTO: 'Visualizar Conf. Ahorrent Porcentajes de descuento',
        EDITAR_CONF_AHORRENT_PROTECCION_PORCENTAJES_DESCUENTO: 'Editar Conf. Ahorrent Porcentajes de descuento',
        VISUALIZAR_CONF_AHORRENT_PROTECCION_SEGUROS_SERVICIOS: 'Visualizar Conf. Ahorrent Seguros y Servicios',
        EDITAR_CONF_AHORRENT_PROTECCION_SEGUROS_SERVICIOS: 'Editar Conf. Ahorrent Seguros y Servicios',

        //PENDIENTE UNIDAD
        VISUALIZAR_PENDIENTE_UNIDAD: 'Visualizar pendientes unidad',
        EDITAR_PENDIENTE_UNIDAD: 'Editar pendientes unidad',

        // PERMISOS DE CLIENTES EN PROSPECTOS Y LEADS
        VISUALIZAR_CLIENTES_PROSPECTOS_LEADS: 'Visualizar clientes prospectos y leads',
        EDITAR_CLIENTES_PROSPECTOS_LEADS: 'Editar clientes prospectos y leads',

        // Cambiar asesor al crear un prospecto lead
        CAMBIAR_ASESOR_PROSPECTO_LEAD: 'Cambiar asesor en prospecto lead',

        // Reasignación de prospectos y leads
        REASIGNACION_PROSPECTOS_LEADS: 'Reasignación de prospectos y leads',


        //“Porcentaje descuento Mano Obra
        PORCENTAJE_DESCUENTO_MANO_OBRA: 'Porcentaje descuento mano de obra',

        // Traslado de unidades Ahorrent
        TRASLADO_UNIDADES_AHORRENT: 'Traslado de unidades Ahorrent',

        // Edición de observaciones de cliente Ahorrent
        EDICION_OBSERVACIONES_CLIENTE_AHORRENT: 'Edición de observaciones de cliente Ahorrent',

        EDICION_RECLAMOS_RENTA_CLIENTE_AHORRENT: 'Edición de reclamos de renta de cliente Ahorrent',

        EDICION_RECLAMOS_GENERALES_CLIENTE_AHORRENT: 'Edición de reclamos generales de cliente Ahorrent',

        RESUMEN_CLIENTE_AHORRENT: 'Resumen de cliente Ahorrent',

        //Solicitudes de Traspasos de Vehiculos
        VER_SOLICITUDES_CREADAS_POR_OTROS_USUARIOS: 'Ver solicitudes creadas por otros usuarios',
        ELIMINAR_SOLICITUD_TRASPASO: 'Eliminar solicitud de traspaso',
        CAMBIO_MANUAL_ESTADO: 'Cambio manual de estado',
        LECTURA_SOLICITUD_TRASPASO: 'Lectura de Solicitud de Traspaso',
        EDITAR_SOLICITUD_TRASPASO: 'Editar la Solicitud de Traspaso',
        AGREGAR_NUEVA_SOLICITUD_TRASPASO: 'Agregar nueva solicitud traspaso',
        LISTADO_SOLICITUDES_TRASPASOS: 'Listado de solicitudes de traspasos',
        BUZON_DOCUMENTACION_INICIAL: 'Buzón de documentación inicial',
        BUZON_ORDEN_COMPRA: 'Buzón de orden de compra',
        BUZON_DOCUMENTACION_FINAL: 'Buzón de documentación final',
        BUZON_DOCUMENTACION_COMPLETA: 'Buzón de documentación completa',
        BUZON_CITA_CLIENTE: 'Buzón de cita cliente',
        CONFIGURACION_TRASPASOS: 'Configuración de traspasos',
        DASHBOARD_SOLICITUD_TRASPASO: 'Dashboard solicitudes de traspasos',
        CALENDARIO_SOLICITUD_TRASPASO: 'Calendario de solicitudes de traspasos',

        // OrdenTallerKanboard
        LECTURA_ORDEN_TALLER_KANBOARD: 'Lectura de orden taller kanboard',
        EDICION_ORDEN_TALLER_KANBOARD: 'Edición de orden taller kanboard',
        CAMBIO_ESTADO_ORDEN_TALLER_KANBOARD: 'Cambio de estado orden taller kanboard',

        // Bestune
        CONFIGURACION_BESTUNE: 'Configuración Bestune',
        CATALOGO_BESTUNE: 'Catálogo Bestune',
        EDICION_TRADUCCION_BESTUNE: 'Edición de traducción a español Bestune',
        EDICION_CODIGO_RETORNO_BESTUNE: 'Edición de código de retorno Bestune',


        CUALQUIERA: null,
    }

    public nacionalidad = [
        { id: 'guatemalteco', nombre: 'Guatemalteco' },
        { id: 'extranjero', nombre: 'Extranjero' },
    ]

    public marcaTarjetaCredito = [
        { id: 'visa', nombre: 'Visa' },
        { id: 'mastercard', nombre: 'Mastercard' },
        { id: 'americanexpress', nombre: 'American Express' }, 
        { id: 'dinersclub', nombre: 'Diners Club' },
    ]

    public rebaja = [
        { id: 'si', nombre: 'SÍ' },
        { id: 'no', nombre: 'NO' },
    ]

    public tiposCombustibles = [
        { id: 'gasolina', nombre: 'Gasolina' },
        { id: 'diesel', nombre: 'Diesel' },
    ]

    public estadosSolicitudPDI = [  
        { id: 'en-proceso', nombre: 'En proceso', color: 'yellow' },
        { id: 'autorizada', nombre: 'Autorizada', color: 'yellow' },
        { id: 'kanboard', nombre: 'Kanboard', color: 'yellow' },
        { id: 'finalizada', nombre: 'Finalizada' , color: '#d6fca4'}, 
        { id: 'cancelada', nombre: 'Cancelada', color: '#d9d9d9' },
    ]

    public estadosSolicitudTraspaso = [
        { id: 'documentacion-inicial', nombre: 'Documentación Inicial', color: 'yellow', colorTabla: '#FE2EF5' },
        { id: 'pre-orden', nombre: 'Pre-orden', color: 'yellow', colorTabla: '#2EFEF7' },
        { id: 'documentacion-final', nombre: 'Documentación Final', color: 'yellow', colorTabla: '#FFFF00' },
        { id: 'documentacion-completa', nombre: 'Documentación Completa' , color: '#d6fca4', colorTabla: '#2E8DFE'},
        { id: 'cita', nombre: 'Cita', color: 'yellow', colorTabla: '#FF8000'},
        { id: 'finalizada', nombre: 'Finalizada', color: '#d6fca4', colorTabla: '#00FF00'},
        { id: 'cancelado', nombre: 'Cancelado', color: '#FF0000', colorTabla: '#FF0000' },
    ]

    public booleansSolicitudTraspaso = [
        { id: 'si', nombre: 'Sí' },
        { id: 'no', nombre: 'No' },
    ]

    public coloresCita = [
        { id: 'rojo', nombre: 'Rojo', color: '#FF0000' },
        { id: 'verde', nombre: 'Verde', color: '#00FF00' },
        { id: 'naranja', nombre: 'Naranja', color: '#FF8000' },
        { id: 'celeste', nombre: 'Celeste', color: '#2EFEF7' },
    ]

    public estadosPresupuestos = [
        // Abierto
        { id: 'abierto', nombre: 'Abierto', color: 'yellow' },
        // Autorizado
        { id: 'autorizado', nombre: 'Autorizado', color: '#02B926' },
        // Parcial
        { id: 'parcial', nombre: 'Parcial', color: 'yellow' },
        // Rechazado    
        { id: 'rechazado', nombre: 'Rechazado', color: '#d9d9d9' },
        // Anulado
        { id: 'anulado', nombre: 'Anulado', color: '#d9d9d9' },
        // Finalizado
        { id: 'finalizado', nombre: 'Finalizado', color: '#d6fca4' },
    ]

    public tipoVenta = [
        { id: 'altas', nombre: 'Altas' },
        { id: 'bajas', nombre: 'Bajas' },
        { id: 'cambios', nombre: 'Cambios' },
    ]

    public prioridad= [
        { id: 'alta', nombre: 'Alta' },
        { id: 'media', nombre: 'Media' },
        { id: 'baja', nombre: 'Baja' },
    ]

    public tipoItem = [
        //Mano de obra
        { id: 'mano-obra', nombre: 'Mano de obra' },
        //Repuestos
        { id: 'repuesto', nombre: 'Repuesto' },
    ]

    public historialAccion = [
        //Agregado
        { id: 'A', nombre: 'Agregado' },
        // Rechazado
        { id: 'R', nombre: 'Rechazado' },
    ]

    public paisesRequisicion = [
        { id: 'alemania', nombre: 'ALEMANIA' },
        { id: 'mexico', nombre: 'MEXICO' },
        { id: 'brasil', nombre: 'BRASIL' },
        { id: 'argentina', nombre: 'ARGENTINA' },
        { id: 'el salvador', nombre: 'EL SALVADOR' },
        { id: 'honduras', nombre: 'HONDURAS' },
        { id: 'guatemala', nombre: 'GUATEMALA' },
    ]

    public viaRequisicion = [
        { id: 'maritimo', nombre: 'MARITIMO' },
        { id: 'aereo', nombre: 'AEREO' },
        { id: 'terrestre', nombre: 'TERRESTRE' },
        { id: 'courier', nombre: 'COURIER' },
    ]

    public etapaTallerRequisicion = [
        { id: 'rep', nombre: 'REP - VEHICULO CIRCULANDO' },
        { id: 'ert', nombre: 'ERT - VEHICULOS DETENIDOS' },
        { id: 'vehiculo-nuevo', nombre: 'VEHICULO NUEVO' },
        { id: 'vehiculo-exhibicion', nombre: 'VEHICULO DE EXHIBICION' },
    ]

    public canalRequisicion = [
        { id: 'aseguradora', nombre: 'ASEGURADORA' },
        { id: 'kollision', nombre: 'KOLLISION' },
        { id: 'mayoreo', nombre: 'MAYOREO' },
        { id: 'mostrador', nombre: 'MOSTRADOR' },
        { id: 'taller', nombre: 'TALLER' },
        { id: 'televentas', nombre: 'TELEVENTAS' },
        { id: 'web', nombre: 'WEB' },
        { id: 'flotas', nombre: 'FLOTAS' },
    ]

    public estadosRequisicion = [
        { id: 'creada', nombre: 'Creada' },
        { id: 'pendiente-de-cotizacion', nombre: 'Pendiente de Cotización' },
        { id: 'pendiente-de-validacion', nombre: 'Pendiente de Validación' },
        { id: 'pendiente-autorizacion-garantia', nombre: 'Pendiente de Autorización de Garantía' },
        { id: 'aprobacion-de-cotizacion', nombre: 'Aprobación de Cotización' },
        { id: 'colocacion-de-pedido', nombre: 'Colocación de Pedido' },
        { id: 'estatus-del-requerimiento', nombre: 'Estatus del Requerimiento' },
        { id: 'pedido-completo-pendiente-de-despacho', nombre: 'Pedido completo pendiente de despacho' },
        { id: 'pedido-finalizado', nombre: 'Pedido Finalizado' },
        { id: 'anulacion-de-pedido', nombre: 'Anulación de Pedido' },
    ]

    public semaforosConfiguracionRequisicion = [
        { id: 'semaforoDiasPedidosAbiertos', nombre: 'Semáforo Días Pedidos Abiertos' },
        { id: 'semaforoDiasHastaEntrega', nombre: 'Semáforo Días Hasta Entrega' },
        { id: 'semaforoDiasPendienteEntrega', nombre: 'Semáforo Días Pendientes de Entrega' },
    ]

    public colorSemaforoConfiguracionRequisicion = [
        { id: 'green', nombre: 'Verde' },
        { id: 'yellow', nombre: 'Amarillo' },
        { id: 'red', nombre: 'Rojo' },
    ]

    public permisosModel = [
        { nombre: 'Súper Admin', modulo: 'General' },
        { nombre: 'Administrador', modulo: 'General' },
        { nombre: 'Editar usuarios', modulo: 'General' },
        { nombre: 'Eliminar usuarios', modulo: 'General' },
        { nombre: 'Visualizar empresas', modulo: 'General' },
        { nombre: 'Editar empresas', modulo: 'General' },
        { nombre: 'Auditor', modulo: 'Formularios IVE' },
        { nombre: 'Visualizar ventas', modulo: 'Formularios IVE' },
        { nombre: 'Editar ventas', modulo: 'Formularios IVE' },
        { nombre: 'Visualizar formularios', modulo: 'Formularios IVE' },
        { nombre: 'Editar formularios', modulo: 'Formularios IVE' },
        { nombre: 'Visualizar clientes', modulo: 'Formularios IVE' },
        { nombre: 'Editar clientes', modulo: 'Formularios IVE' },
        { nombre: 'Visualizar productos', modulo: 'Formularios IVE' },
        { nombre: 'Visualizar usuarios', modulo: 'Formularios IVE' },
        { nombre: 'Cerrar formularios', modulo: 'Formularios IVE' },
        { nombre: 'Exportar formularios', modulo: 'Formularios IVE' },
        { nombre: 'Editar productos', modulo: 'Formularios IVE' },
        { nombre: 'Matriz de riesgo', modulo: 'Formularios IVE' },
        { nombre: 'Ajustes generales de matriz de riesgo', modulo: 'Formularios IVE' },
        { nombre: 'Eliminar ventas y formularios', modulo: 'Formularios IVE' },
        { nombre: 'Configuración de avalúo', modulo: 'Avalúos' },
        { nombre: 'Editar Formularios Cerrados', modulo: 'Avalúos' },
        { nombre: 'Creación de solicitud de avalúo', modulo: 'Avalúos' },
        { nombre: 'Módulo de avalúo', modulo: 'Avalúos' },
        { nombre: 'Realización de avalúo', modulo: 'Avalúos' },
        { nombre: 'Confirmación de avalúo', modulo: 'Avalúos' },
        { nombre: 'Ver avalúos confirmados', modulo: 'Avalúos' },
        { nombre: 'Cancelar avalúos', modulo: 'Avalúos' },
        { nombre: 'Dashboard de avalúos', modulo: 'Avalúos' },
        { nombre: 'Reporte de avalúos', modulo: 'Avalúos' },
        { nombre: 'Reporte coaches de avalúos', modulo: 'Avalúos' },
        { nombre: 'Reporte gerencial de Avalúos', modulo: 'Avalúos' },
        { nombre: 'Dashboard comercial de avalúos', modulo: 'Avalúos' },
        { nombre: 'Modificación de avalúos', modulo: 'Avalúos' },
        { nombre: 'Editar membretes de empresa', modulo: 'Hojas membretadas' },
        { nombre: 'Visualización de membretes de empresa', modulo: 'Hojas membretadas' },
        { nombre: 'Creación de hojas membretadas', modulo: 'Hojas membretadas' },
        { nombre: 'Aprobación de hojas membretadas', modulo: 'Hojas membretadas' },
        { nombre: 'Desbloquear hojas membretadas aprobadas', modulo: 'Hojas membretadas' },
        { nombre: 'Reporte de hojas membretadas', modulo: 'Hojas membretadas' },
        { nombre: 'Visualizar unidades', modulo: 'Ahorrent' },
        { nombre: 'Editar unidades', modulo: 'Ahorrent' },
        { nombre: 'Visualizar clientes ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Editar clientes ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Cambios de estado de unidades Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Cambios de estado de contratos Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Ingreso a servicio de unidades Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Visualizar contratos ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Editar contratos ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Abrir contratos cerrados ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Visualizar reservas Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Editar reservas Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Servicios pendientes unidades ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Configuración de ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Listado de encuestas individuales Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Listado de encuestas corporativas Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Gráficas de encuestas Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Encuesta individual', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Encuesta individual', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Encuesta corporativa', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Encuesta corporativa', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Textos encuesta finalizada', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Textos encuesta finalizada', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Servicios por kilometraje', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Servicios por kilometraje', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Cambio dólar', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Cambio dólar', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Precios de combustible', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Precios de combustible', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Protección contra choques', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Protección contra choques', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Porcentajes de eficiencia', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Porcentajes de eficiencia', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Porcentajes de descuento', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Porcentajes de descuento', modulo: 'Ahorrent' },
        { nombre: 'Visualizar Conf. Ahorrent Seguros y Servicios', modulo: 'Ahorrent' },
        { nombre: 'Editar Conf. Ahorrent Seguros y Servicios', modulo: 'Ahorrent' },
        { nombre: 'Dashboard de agentes Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Dashboard gerencial Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Dashboard pilotos Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Dashboard análisis rentas Ahorrent', modulo: 'Ahorrent' },
        { nombre: 'Editar inspección de salida', modulo: 'Ahorrent' },
        { nombre: 'Editar inspección de entrada', modulo: 'Ahorrent' },
        { nombre: 'Visualizar imagen unidad', modulo: 'Ahorrent'},
        { nombre: 'Editar imagen unidad', modulo: 'Ahorrent'},
        { nombre: 'Visualizar depreciación de unidad', modulo: 'Ahorrent' },
        { nombre: 'Editar depreciación de unidad', modulo: 'Ahorrent' },
        { nombre: 'Visualizar pendientes unidad', modulo: 'Ahorrent' },
        { nombre: 'Editar pendientes unidad', modulo: 'Ahorrent' },
        { nombre: 'Traslado de unidades Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Edición de observaciones de cliente Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Edición de reclamos de renta de cliente Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Edición de reclamos generales de cliente Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Resumen de cliente Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Registrar lavado especial Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Aprobar lavado especial Ahorrent', modulo: 'Ahorrent'},
        { nombre: 'Editar concesionarios', modulo: 'Citas' },
        { nombre: 'Visualización de citas', modulo: 'Citas' },
        { nombre: 'Aprobación de citas', modulo: 'Citas' },
        { nombre: 'Agente para reservar citas', modulo: 'Citas' },
        { nombre: 'Reporte de citas', modulo: 'Citas' },
        { nombre: 'Editar servicios', modulo: 'Citas' },
        { nombre: 'Visualizar servicios', modulo: 'Citas' },
        { nombre: 'Editar repuestos', modulo: 'Citas' },
        { nombre: 'Visualizar repuestos', modulo: 'Citas' },
        { nombre: 'Editar versiones', modulo: 'Citas' },
        { nombre: 'Visualizar versiones', modulo: 'Citas' },
        { nombre: 'Configuración de landing page', modulo: 'Landing pages' },
        { nombre: 'Configuración de campañas', modulo: 'Landing pages' },
        { nombre: 'Creación de solicitud de prueba de manejo', modulo: 'Digitalización de boletas' },
        { nombre: 'Edición de solicitud de prueba de manejo', modulo: 'Digitalización de boletas' },
        { nombre: 'Lectura de solicitud de prueba de manejo', modulo: 'Digitalización de boletas' },
        { nombre: 'Aprobar solicitudes de prueba de manejo', modulo: 'Digitalización de boletas' },
        { nombre: 'Edición de vehículos de solicitud de prueba de manejo', modulo: 'Digitalización de boletas'},
        { nombre: 'Lectura de vehículos de solicitud de prueba de manejo', modulo: 'Digitalización de boletas'},
        { nombre: 'Visualizar clientes boleta', modulo: 'Digitalización de boletas' },
        { nombre: 'Editar clientes boleta', modulo: 'Digitalización de boletas' },
        { nombre: 'Aprobar solicitudes de prestamo', modulo: 'Digitalización de boletas' },
        { nombre: 'Calendario de solicitudes de prueba de manejo', modulo: 'Digitalización de boletas' },
        { nombre: 'Reportes de solicitudes de prueba de manejo', modulo: 'Digitalización de boletas' },
        { nombre: 'Visualizar clientes planes mantenimientos', modulo: 'Planes de mantenimiento' },
        { nombre: 'Editar clientes planes mantenimientos', modulo: 'Planes de mantenimiento' },
        { nombre: 'Visualizar vehículos planes mantenimientos', modulo: 'Planes de mantenimiento' },
        { nombre: 'Editar vehículos planes mantenimientos', modulo: 'Planes de mantenimiento' },
        { nombre: 'Visualizar planes mantenimientos', modulo: 'Planes de mantenimiento' },
        { nombre: 'Editar planes mantenimientos', modulo: 'Planes de mantenimiento' },
        { nombre: 'Visualizar contrataciones', modulo: 'Planes de mantenimiento' },
        { nombre: 'Editar contrataciones', modulo: 'Planes de mantenimiento' },
        { nombre: 'Visualizar ordenes de trabajo', modulo: 'Planes de mantenimiento' },
        { nombre: 'Editar ordenes de trabajo', modulo: 'Planes de mantenimiento' },
        { nombre: 'Lectura de solicitud de venta', modulo: 'Solicitudes PDI' },
        { nombre: 'Creación de solicitud de venta', modulo: 'Solicitudes PDI' },
        { nombre: 'Autorización de solicitudes ventas', modulo: 'Solicitudes PDI' },
        { nombre: 'Agregar accesorios fuera de acuerdo', modulo: 'Solicitudes PDI' },
        { nombre: 'Envio de solicitud de venta a kanboard', modulo: 'Solicitudes PDI' },
        { nombre: 'Cambio de envio de placas', modulo: 'Solicitudes PDI'},
        { nombre: 'Datos de ventas', modulo: 'Solicitudes PDI' },
        { nombre: 'Descargar tareas kanboard', modulo: 'Solicitudes PDI' },
        { nombre: 'Cambio de estatus solicitudes PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Cancelar solicitudes PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Reporte de PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Lectura de orden taller kanboard', modulo: 'Solicitudes PDI' },
        { nombre: 'Edición de orden taller kanboard', modulo: 'Solicitudes PDI' },
        { nombre: 'Cambio de estado orden taller kanboard', modulo: 'Solicitudes PDI' },
        { nombre: 'Edición de campos especiales solicitudes PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Exhibiciones PDI', modulo: 'Exhibiciones PDI' },
        { nombre: 'Nueva exhibiciones PDI', modulo: 'Exhibiciones PDI' },
        { nombre: 'Datos exhibición', modulo: 'Exhibiciones PDI' },
        { nombre: 'Cambio de estatus exhibiciones PDI', modulo: 'Exhibiciones PDI' },
        { nombre: 'Cancelar exhibiciones PDI', modulo: 'Exhibiciones PDI' },
        { nombre: 'Licitaciones PDI', modulo: 'Licitaciones PDI' },
        { nombre: 'Nueva licitación PDI', modulo: 'Licitaciones PDI' },
        { nombre: 'Datos licitaciones', modulo: 'Licitaciones PDI' },
        { nombre: 'Cambio de estatus licitaciones PDI', modulo: 'Licitaciones PDI' },
        { nombre: 'Cancelar licitaciones PDI', modulo: 'Licitaciones PDI' },
        { nombre: 'Lectura de inspección de salida PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Edición de inspección de salida PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Lectura de inspección de entrada PDI', modulo: 'Solicitudes PDI' },
        { nombre: 'Edición de inspección de entrada PDI', modulo: 'Solicitudes PDI' },


        { nombre: 'Visualizar clientes presupuesto', modulo: 'Presupuesto' },
        { nombre: 'Editar clientes presupuesto', modulo: 'Presupuesto' },
        { nombre: 'Configuración presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Visualizar presupuesto', modulo: 'Presupuesto' },
        { nombre: 'Editar presupuesto', modulo: 'Presupuesto' },
        { nombre: 'Eliminar presupuesto', modulo: 'Presupuesto' },
        
        // Requisiciones
        { nombre: 'Configuración de requisiciones', modulo: 'Requisiciones' },
        { nombre: 'Creación de nuevas requisiciones', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones', modulo: 'Requisiciones' },
        { nombre: 'Edición de requisiciones', modulo: 'Requisiciones' },
        { nombre: 'Cancelar requisiciones', modulo: 'Requisiciones' },
        { nombre: 'Forzar cambio de estado de requisición', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones creadas', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones pendientes de cotización', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones pendientes de validación', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones aprobación de cotización', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones colocación de pedido', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones estatus del requerimiento', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones pedido completo pendiente de despacho', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones pedido finalizado', modulo: 'Requisiciones' },
        { nombre: 'Lectura de requisiciones anulación de pedido', modulo: 'Requisiciones' },
        { nombre: 'Autorización de requisición taller', modulo: 'Requisiciones' },
        { nombre: 'Lectura de todas las requisiciones', modulo: 'Requisiciones' },
        { nombre: 'Autorización de Ingreso, Backorder y Despachado en Requisiciones', modulo: 'Requisiciones' },
        
        //Cambio estado presupuesto
        { nombre: 'Cambios de estado de presupuestos', modulo: 'Presupuesto'},

        { nombre: 'Visualizar puntos presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Editar puntos presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Eliminar puntos presupuesto', modulo: 'Presupuesto'},

        // item mano de obra
        { nombre: 'Visualizar item mano de obra', modulo: 'Presupuesto'},
        { nombre: 'Editar item mano de obra', modulo: 'Presupuesto'},
        { nombre: 'Eliminar item mano de obra', modulo: 'Presupuesto'},

        // item repuesto
        { nombre: 'Visualizar item repuesto', modulo: 'Presupuesto'},
        { nombre: 'Editar item repuesto', modulo: 'Presupuesto'},
        { nombre: 'Eliminar item repuesto', modulo: 'Presupuesto'},

        //Rechazo mano de obra
        { nombre: 'Rechazo mano de obra', modulo: 'Presupuesto'},
        { nombre: 'Autorizar repuesto importado', modulo: 'Presupuesto'},
        { nombre: 'Autorizar repuesto bodega local', modulo: 'Presupuesto'},

        // Permisos historial items 
        { nombre: 'Visualizar historial items', modulo: 'Presupuesto'},
        //Descargar excel presupuestos
        { nombre: 'Descargar excel presupuestos', modulo: 'Presupuesto'},

        // Facturas SV
        { nombre: 'Listado de facturas El Salvador de nuevos y usados', modulo: 'Facturación SV' },
        { nombre: 'Listado de facturas El Salvador de repuestos', modulo: 'Facturación SV' },
        { nombre: 'Listado de facturas El Salvador de taller y servicios', modulo: 'Facturación SV' },
        { nombre: 'Listado de facturas El Salvador de exportación de vehículos', modulo: 'Facturación SV' },
        { nombre: 'Listado de facturas El Salvador de exportación de repuestos', modulo: 'Facturación SV' },
        { nombre: 'Listado de facturas El Salvador de Ahorrent', modulo: 'Facturación SV' },
        { nombre: 'Edición de datos de facturas El Salvador', modulo: 'Facturación SV' },
        { nombre: 'Emisión de documentos El Salvador', modulo: 'Facturación SV' },
        { nombre: 'Anulación de documentos El Salvador', modulo: 'Facturación SV' },
        { nombre: 'Configuración de módulo Facturas El Salvador', modulo: 'Facturación SV' },

        //Solicitudes de Traspasos de Vehiculos
        { nombre: 'Ver solicitudes creadas por otros usuarios', modulo: 'Digitalización de traspasos' },
        { nombre: 'Eliminar solicitud de traspaso', modulo: 'Digitalización de traspasos' },
        { nombre: 'Cambio manual de estado', modulo: 'Digitalización de traspasos' },
        { nombre: 'Lectura de Solicitud de Traspaso', modulo: 'Digitalización de traspasos' },
        { nombre: 'Editar la Solicitud de Traspaso', modulo: 'Digitalización de traspasos' },
        { nombre: 'Agregar nueva solicitud traspaso', modulo: 'Digitalización de traspasos' },
        { nombre: 'Listado de solicitudes de traspasos', modulo: 'Digitalización de traspasos' },
        { nombre: 'Buzón de documentación inicial', modulo: 'Digitalización de traspasos' },
        { nombre: 'Buzón de orden de compra', modulo: 'Digitalización de traspasos' },
        { nombre: 'Buzón de documentación final', modulo: 'Digitalización de traspasos' },
        { nombre: 'Buzón de documentación completa', modulo: 'Digitalización de traspasos' },
        { nombre: 'Buzón de cita cliente', modulo: 'Digitalización de traspasos' },
        { nombre: 'Configuración de traspasos', modulo: 'Digitalización de traspasos' },
        { nombre: 'Dashboard solicitudes de traspasos', modulo: 'Digitalización de traspasos' },
        { nombre: 'Calendario de solicitudes de traspasos', modulo: 'Digitalización de traspasos' },

        //Visualizar datos generales presupuestos
        { nombre: 'Visualizar datos generales presupuestos', modulo: 'Presupuesto'},
        //Editar
        { nombre: 'Editar datos generales presupuestos', modulo: 'Presupuesto'},
          //ENVIAR CORREO CLIENTE PRESUPUESTO
        { nombre: 'Enviar correo cliente presupuesto', modulo: 'Presupuesto'},
        //Validar presupuestos
        { nombre: 'Validar presupuestos', modulo: 'Presupuesto'},
        //Presupuesto recuperado
        { nombre: 'Presupuesto recuperado', modulo: 'Presupuesto'},
        { nombre: 'Anular presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Cambiar a estado abierto presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Finalizar presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Enviar correo repuestos presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Enviar correo mano de obra presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Descargar presupuesto presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Preview de presupuesto presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Configurar prioridad presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Configurar porcentaje de descuento mano de obra presupuesto', modulo: 'Presupuesto'},
        { nombre: 'Porcentaje descuento mano de obra', modulo: 'Presupuesto'},
        // Prospectos y leads
        { nombre: 'Configuración de Lead', modulo: 'Prospectos y lead' },
        { nombre: 'Lectura de prospectos leads', modulo: 'Prospectos y lead' },
        { nombre: 'Edición de prospectos leads', modulo: 'Prospectos y lead' },
        { nombre: 'Cambio de estado prospecto lead', modulo: 'Prospectos y lead' },
        { nombre: 'Seguimiento de prospectos y leads', modulo: 'Prospectos y lead' },
        { nombre: 'Acceso a ver todos los prospectos y leds', modulo: 'Prospectos y lead' },
        { nombre: 'Seguimiento a Leds de otros usuarios', modulo: 'Prospectos y lead' },
        { nombre: 'Cambio estatus Finalizados', modulo: 'Prospectos y lead' },
        { nombre: 'Reporte de prospectos y leads', modulo: 'Prospectos y lead' },
        { nombre: 'Visualizar clientes prospectos y leads', modulo: 'Prospectos y lead' },
        { nombre: 'Editar clientes prospectos y leads', modulo: 'Prospectos y lead' },
        { nombre: 'Cambiar asesor en prospecto lead', modulo: 'Prospectos y lead' },
        { nombre: 'Reasignación de prospectos y leads', modulo: 'Prospectos y lead' },

        // Bestune
        { nombre: 'Configuración Bestune', modulo: 'Bestune' },
        { nombre: 'Catálogo Bestune', modulo: 'Bestune' },
        { nombre: 'Edición de traducción a español Bestune', modulo: 'Bestune' },
        { nombre: 'Edición de código de retorno Bestune', modulo: 'Bestune' },
    ]

    public personaDigitalizacion = [
        { id: 'juridico', nombre: 'Jurídico' },
        { id: 'individual', nombre: 'Individual' },
    ]

    public booleano = [
        { id: true, nombre: 'Sí' },
        { id: false, nombre: 'No' },
    ]

    //Amarillo, azul, verde, purpura, rojo, naranja, gris, marron, gris oscuro, rosado, verde azulado, azul claro, lima, verde claro, ambar 
    public coloresOrdenesKanboard = [
        { id: 'yellow', nombre: 'AMARILLO' },
        { id: 'blue', nombre: 'AZUL' },
        { id: 'green', nombre: 'VERDE' },
        { id: 'purple', nombre: 'PURPURA' },
        { id: 'red', nombre: 'ROJO' },
        { id: 'orange', nombre: 'NARANJA' },
        { id: 'grey', nombre: 'GRIS' },
        { id: 'brown', nombre: 'MARRON' },
        { id: 'deep_orange', nombre: 'NARANJA OSCURO' },
        { id: 'dark_gray', nombre: 'GRIS OSCURO' },
        { id: 'pink', nombre: 'ROSADO' },
        { id: 'teal', nombre: 'VERDE AZULADO' },
        { id: 'cyan', nombre: 'AZUL CLARO' },
        { id: 'lime', nombre: 'LIMA' },
        { id: 'light_green', nombre: 'VERDE CLARO' },
        { id: 'amber', nombre: 'AMBAR' },
    ]

    public IVARequisiciones = 1.12;


    public obtenerNombre(array, id){
        for(let element of array){
            if(element.id == id){
                return element.nombre;
            }
        }

        return '';
    }

    public obtenerDeDiccionario(array, value, atributoArray, atributoRetornar){
        for(let element of array){
            if(element[atributoArray] == value){
                return element[atributoRetornar];
            }
        }
        return value;
    }

    public currencyFormat(value: number = 0, location: string) {
        let localFormat = Intl.NumberFormat(location,
            {
                minimumFractionDigits: 2
            }
        );
        return localFormat.format(value);
    }
}
